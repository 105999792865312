import React from "react"
import "./Header.scss"
import logo from "../images/logo.svg"
import { Link } from "gatsby"

export default function Header() {
  return (
    <div className="Header pure-menu pure-menu-horizontal">
      <div className="Header-content">
        <a href="#" className="Logo">
          <img src={logo} alt="Logo" />
        </a>
        <ul className="pure-menu-list">
            <li className="pure-menu-item"><Link to="/">TODAY'S SALES</Link></li>
          <li className="pure-menu-item"><Link to="/alert">ALERTS</Link></li>
        </ul>
      </div>
    </div>
  )
}

import React, { useState } from "react"

const Context = React.createContext([{}, () => {}])

const ContextProvider = props => {
  const [state, setState] = useState({
    isLoaded : false,
    categories: [
      {
        id: 0,
        name: "All",
        selected: "selected",
      },
      {
        id: 1,
        name: "Womens",
      },
      {
        id: 2,
        name: "Mens",
      },
      {
        id: 3,
        name: "Baby",
      },
    ],
    tracks: [
      {
        name: "Lost Chameleon - Genesis",
      },
      {
        name: "The Hipsta - Shaken Soda",
      },
      {
        name: "Tobu - Such Fun",
      },
    ],
    filters: [
      {
        id: 0,
        name: "Everything",
        checked: false,
        category_level: 1,
      },
      {
        id: 1,
        name: "Regular Priced Items",
        checked: false,
        category_level: 1,
      },
      {
        id: 2,
        name: "Any 1 Item",
        checked: false,
        category_level: 1,
      },
      {
        id: 3,
        name: "Select styles",
        checked: false,
        category_level: 1,
      },
      {
        id: 4,
        name: "Clearance",
        checked: false,
        category_level: 1,
      },
      {
        id: 5,
        name: "Business Casual",
        checked: false,
        category_level: 2,
      },
      {
        id: 6,
        name: "Active / Athleisure",
        checked: false,
        category_level: 2,
      },
      {
        id: 7,
        name: "Outdoor",
        checked: false,
        category_level: 2,
      },
    ],
    discounts: [],
    apiDiscounts : [],
    alertPreferences: [],
    customDealSelection: [],
    dealList: [],
    stores: []
  })
  return (
    <Context.Provider value={[state, setState]}>
      {props.children}
    </Context.Provider>
  )
}

export { Context, ContextProvider }

import React from "react"
import Helmet from "react-helmet"
import Header from "./Header"

const Layout = ({ children, pageClassName }) => {
  return (
    <>
      <Helmet>
        <title>ThredAlert</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
      </Helmet>
      <header>
        <Header />
      </header>
      <main className={pageClassName}>{children}</main>
    </>
  )
}

export default Layout
